<template>
  <v-menu
    ref="datePickerStartRange"
    v-model="datePickerStartRange"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scroll-y-transition"
    min-width="100px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="6">
            <!-- prepend-inner-icon="search" -->
            <v-text-field
              slot="activator"
              :ref="startDateRef || 'iptPeriodFrom'"
              v-model.trim="periodFrom"
              v-bind="attrs"
              :label="startDateLabel || 'from'"
              :append-icon="startDateAppendIcon || 'calendar_today'"
              placeholder
              readonly
              dense
              outlined
              solo
              flat
              hide-details
              class="date-input mr-2"
              v-on="on"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              slot="activator"
              :ref="endDateRef || 'iptPeriodTo'"
              v-model.trim="periodTo"
              v-bind="attrs"
              :label="endDateLabel || 'to'"
              :append-icon="endDateAppendIcon || 'calendar_today'"
              placeholder
              readonly
              dense
              outlined
              solo
              flat
              hide-details
              class="date-input"
              v-on="on"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-layout>
      <v-date-picker
        v-model.trim="periodFrom"
        no-title
        :first-day-of-week="0"
        locale="ko-kr"
        :min="pickerStartDateFrom"
        :max="pickerStartDateFromLimit"
        :prev-icon="`chevron_left`"
        :next-icon="`chevron_right`"
      >
        <v-spacer />
        <!-- <v-btn
          color="primary"
          @click="datePickerStartRange = false"
        >
          Close
        </v-btn> -->
      </v-date-picker>
      <v-date-picker
        v-model.trim="periodTo"
        no-title
        :first-day-of-week="0"
        locale="ko-kr"
        :min="pickerEndDateFrom"
        :max="pickerEndDateFromLimit"
        :prev-icon="`chevron_left`"
        :next-icon="`chevron_right`"
      >
        <v-spacer />
        <v-btn
          color="primary"
          @click="datePickerStartRange = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="$refs.datePickerStartRange.save(periodTo)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-layout>
  </v-menu>
</template>

<script>
import Dayjs from 'dayjs'

export default {
  props: [
    'from', 'to',
    'startDateRef', 'endDateRef',
    'startDateLabel', 'endDateLabel',
    'startDateAppendIcon', 'endDateAppendIcon'
  ],
  data() {
    return {
      datePickerStartRange: null,
      periodFrom: '',
      periodTo: ''
    }
  },
  computed: {
    pickerStartDateFrom() {
      // .add(1, 'days')
      return Dayjs(new Date())
        .format('YYYY-MM-DD')
    },
    pickerStartDateFromLimit() {
      return Dayjs(new Date())
        .add(3, 'years')
        .format('YYYY-MM-DD')
    },
    pickerEndDateFrom() {
      if (this.periodFrom === null) {
        return null
      }
      return Dayjs(this.periodFrom, 'YYYY-MM-DD')
        .format('YYYY-MM-DD')
        // .add(1, 'days')
    },
    pickerEndDateFromLimit() {
      if (this.periodFrom === null) {
        return null
      }
      return Dayjs(this.periodFrom, 'YYYY-MM-DD')
        .add(3, 'years')
        .format('YYYY-MM-DD')
    }
  },
  watch: {
    from(val) {
      this.periodFrom = this.$options.filters.toDate(val)
    },
    to(val) {
      this.periodTo = this.$options.filters.toDate(val)
    },
    periodFrom() {
      this.$emit('update:from', this.periodFrom)
      this.$emit('update:to', this.periodTo)
      if (this.periodTo === '' || this.periodFrom > this.periodTo) {
        this.periodTo = this.periodFrom
      }
    },
    periodTo() {
      this.$emit('update:to', this.periodTo)
    }
  },
  mounted() {
    this.periodFrom = this.from
    this.periodTo = this.to
  },
  created() {
    this.periodFrom = this.$options.filters.toDate(this.from)
    this.periodTo = this.$options.filters.toDate(this.to)
  }
}
</script>

<style lang="scss" scoped>
.date-input {

  ::v-deep .v-icon.v-icon {
    font-size: 16px;
  }

  ::v-deep label,
  ::v-deep input {
    font-size: 0.875rem;
  }
}
</style>
